import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getTokenWithExpires } from '../utils/auth'
import { ReactComponent as ScreeningIcon } from '../assets/screening.svg'
import { ReactComponent as AccountIcon } from '../assets/account.svg'
import { ReactComponent as QRCodeIcon } from '../assets/qrcode.svg'

const BottomTabBar = () => {
  const location = useLocation()
  const token = getTokenWithExpires()
  const tabs = [
    {
      name: 'Screen',
      icon: <ScreeningIcon className="nav-icon" />,
      url: '/daily-screen',
    },
    {
      name: 'My Badge',
      icon: <QRCodeIcon className="nav-icon" />,
      url: '/my-badge',
    },
    {
      name: 'Account',
      icon: <AccountIcon className="nav-icon" />,
      url: '/account',
    },
  ]

  if (!token) return null

  return (
    <div className="bottom-bar">
      <ul className="nav nav-pills nav-fill">
        {tabs.map(({ name, icon, url }) => (
          <Link
            className={`nav-link nav-item ${
              location.pathname === url ? 'active' : ''
            }`}
            key={name}
            to={url}
          >
            {icon}
            <br />
            {name}
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default BottomTabBar
