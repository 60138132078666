import React, { useState } from 'react'
import Bugsnag from '@bugsnag/js'
import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import GoBack from '../components/GoBack'
import { forgotPassword } from '../api/auth'
import greenTick from '../assets/green-tick.svg'

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [done, setDone] = useState(false)

  const handleSubmit = async e => {
    let n = e.nativeEvent
    e.preventDefault()
    const data = {
      email: n.target[0].value,
    }

    setIsLoading(true)

    try {
      await forgotPassword(data.email)
    } catch (error) {
      Bugsnag.notify(error)
    } finally {
      // Showing success no matter what; if the email doesn't exist,
      // fail silently as per spec.
      setDone(true)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="container standard-page">
      <GoBack to="/login" />
      {done ? (
        <DoneContent />
      ) : (
        <>
          <AppNavbar title="Reset Your Password" />
          <div className="mt-4">
            <p>
              Please enter the email address associated with your account. We
              will send you an email to reset your password.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block btn-screen-submit"
              >
                Send Email
              </button>
            </form>
          </div>
        </>
      )}
    </div>
  )
}

const DoneContent = () => (
  <div className="mt-4">
    <img alt="Success" src={greenTick} />
    <h1 className="mt-2" style={{ fontWeight: '700' }}>
      Thank you!
    </h1>
    <p className="mt-3">
      We received your request. If a user with this email exists, a password
      reset email will arrive soon.
    </p>
  </div>
)

export default ResetPassword
