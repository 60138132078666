import React from 'react'

const NotAuthorized = () => {
  return (
    <>
      <div className="container my-5 text-center">
        <h1>You don't seem to have an account.</h1>
        <p>
          Please contact your administrator to enroll your email address into
          our screening system.
        </p>
      </div>
    </>
  )
}

export default NotAuthorized
