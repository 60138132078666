import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, withRouter, Redirect, useHistory } from 'react-router-dom'
import { getTokenWithExpires, clearCredentials } from '../utils/auth'
import { getProfile, updateProfile } from '../api/user'
import EULAModal from '../components/EULAModal'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const history = useHistory()
  const [user, setUser] = useState({})
  const [isModelOpen, setIsModelOpen] = useState(false)

  const { agreed_eula = true } = user
  const tokenData = getTokenWithExpires()
  const { token } = tokenData || {}

  const closeModal = () => setIsModelOpen(false)
  const handleAgreeEULA = async () => {
    await updateProfile({ agreed_eula: true })
    closeModal()
  }

  const handleDeclineEULA = () => {
    clearCredentials()
    history.push('/login')
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return
      const user = await getProfile()
      setUser(user)
    }
    fetchData()
  }, [token])

  useEffect(() => {
    setIsModelOpen(!agreed_eula)
  }, [agreed_eula])

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!tokenData) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?next=${location.pathname}`,
              }}
            />
          )
        }

        const tokenExpired = Date.now() / 1000 > tokenData.expires_at

        if (tokenExpired) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?session_expired=1&next=${location.pathname}`,
              }}
            />
          )
        }

        return (
          <>
            <Component />
            <EULAModal
              isOpen={isModelOpen}
              onDecline={handleDeclineEULA}
              onAgree={handleAgreeEULA}
            />
          </>
        )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
}

export default withRouter(PrivateRoute)
