import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Bugsnag from '@bugsnag/js'
import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import ButtonLink from '../components/ButtonLink'
import ConfirmModal from '../components/ConfirmModal'
import { clearCredentials } from '../utils/auth'
import { useMounted } from '../utils/hooks'
import { getProfile } from '../api/user'
import { getCountryList } from '../api/country'
import { updateProfile } from '../api/user'
import { COUNTRY_CODE } from '../constants'
import { isPhoneValidWithCountryCode } from '../utils/phoneParser'

import './AccountPage.scss'
import { emailRegex } from '../utils/regex'

const updateAccountTitle = 'Update Account?'
const updateAccountBody = 'Are you sure you want to update your account?'

const AccountPage = () => {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [updateAccountModal, setUpdateAccountModal] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const isMounted = useMounted()

  const [staffId, setStaffId] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [company, setCompany] = useState()
  const [countryOptions, setCountryOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODE.US)
  const toggleUpdateAccount = () => setUpdateAccountModal(!updateAccountModal)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      setError(null)
      try {
        const user = await getProfile()
        const countryList = await getCountryList()
        if (isMounted.current) {
          setCountryOptions(countryList)
          setStaffId(user.id)
          setFirstName(user.first_name)
          setLastName(user.last_name)
          setCompany(user.organization.name)
          const { phone_number, phone_country } = user
          setPhoneNumber(phone_number)
          setSelectedCountry(phone_country.id)
          setEmail(user.email)
        }
      } catch (error) {
        console.error(error)
        Bugsnag.notify(error)
        isMounted.current &&
          setError('There was an error loading data. Please refresh the page.')
      }
      isMounted.current && setIsLoading(false)
    }
    fetchData()
  }, [isMounted])

  const getCountryISOCode = (countryId, countries) => {
    const country = countries.find(country => country.id === countryId)
    if (!country) return ''
    return country.iso_code
  }

  const updateAccount = async () => {
    setError(null)

    if (!staffId) {
      setError('Unable to load profile. Please refresh the page.')
      return
    }

    const ISOCode = getCountryISOCode(selectedCountry, countryOptions)
    const isPhoneValid = isPhoneValidWithCountryCode(ISOCode, phoneNumber)
    const isEmailValid = emailRegex.test(email)
    if (!isPhoneValid) {
      setError('Please enter phone number in correct format.')
      return
    }
    if (!isEmailValid) {
      setError('Please enter email in correct format.')
      return
    }

    setIsLoading(true)

    try {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        phone_country_id: selectedCountry,
      }
      updateProfile(payload)
      setSuccess('Updated successfully')
    } catch (error) {
      Bugsnag.notify(error)
      setError(error)
    }
    setIsLoading(false)
  }

  const doUpdateAccount = async e => {
    toggleUpdateAccount()
    updateAccount()
  }

  if (email === null || isLoading) {
    return <Loading />
  }

  return (
    <div className="standard-page account-page">
      <div className="container">
        <AppNavbar
          title="Account"
          button="Save"
          onClick={toggleUpdateAccount}
        />
      </div>
      <div className="container">
        <div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}

          <form id="accountForm">
            <Row>
              <Col xs="6">
                <div className="form-group">
                  <label htmlFor="name">First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder=""
                    required
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                  />
                </div>
              </Col>
              <Col xs="6">
                <div className="form-group">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder=""
                    required
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <label htmlFor="company">Company</label>
              <input
                className="form-control"
                type="text"
                name="company"
                id="company"
                value={company}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <div className="input-group phone-number-container">
                <select
                  className="custom-select country-code-select"
                  value={selectedCountry}
                  onChange={e => setSelectedCountry(Number(e.target.value))}
                >
                  {countryOptions.map(({ iso_code, id, code }) => (
                    <option key={id} value={id}>
                      {iso_code} (+{code})
                    </option>
                  ))}
                </select>
                <div className="input-group-append">
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Your phone number"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className="form-control"
                type="email"
                name="email"
                id="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </form>
          <div className="mt-5 pb-4">
            <Link
              className="text-primary font-weight-bold d-block"
              to="/change-password"
            >
              Change Password
            </Link>
            <hr />
            <ButtonLink
              classNames="text-danger d-block"
              handleClick={() => {
                clearCredentials()
                window.open('https://www.screenmein.com', '_self')
              }}
            >
              Sign Out
            </ButtonLink>
            <hr />
            <a
              className="text-primary font-weight-bold d-block"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.screenmein.com/privacy"
            >
              Privacy Policy
            </a>
            <hr />
            <p class="note">
              If you want to remove your account, please ask your organization
              administrator.
            </p>
          </div>
        </div>
      </div>
      <ConfirmModal
        title={updateAccountTitle}
        body={updateAccountBody}
        isOpen={updateAccountModal}
        toggle={toggleUpdateAccount}
        onAction={doUpdateAccount}
      />
    </div>
  )
}

export default AccountPage
