import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'

import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import ChangePasswordForm from '../components/ChangePasswordForm'
import GoBack from '../components/GoBack'
import { clearCredentials } from '../utils/auth'
import { resetPassword } from '../api/auth'
import { changeMyPassword } from '../api/user'

const ChangePassword = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  const history = useHistory()

  const resetToken = new URLSearchParams(props.location.search).get(
    'resetToken'
  )

  if (isLoading) {
    return <Loading />
  }

  const handleSubmit = async (data, e) => {
    e.preventDefault()

    const { oldPassword, password, passwordConfirm } = data
    if (password !== passwordConfirm) {
      setError("Passwords don't match.")
      return
    }

    setIsLoading(true)
    try {
      if (resetToken && resetToken.length > 0) {
        await resetPassword({ token: resetToken, password })
      } else {
        await changeMyPassword({ password, old_password: oldPassword })
      }
      clearCredentials()
      history.push('/change-password-success')
    } catch (error) {
      Bugsnag.notify(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className="container standard-page">
      {!resetToken && <GoBack />}
      <AppNavbar title="Change Password" />
      <div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <ChangePasswordForm token={resetToken} handleSubmit={handleSubmit} />
      </div>
    </div>
  )
}

export default ChangePassword
