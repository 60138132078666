import React, { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { getProfile } from '../api/user'
import { getCheckInStatus } from '../api/checkIn'
import { useMounted } from '../utils/hooks'
import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import greenTickSvg from '../assets/green-tick-filled.svg'
import symptomCheckSvg from '../assets/symptom-check.svg'
import temperatureScanSvg from '../assets/temperature-scan.svg'
import './MyBadge.scss'

const MyBadge = () => {
  const isMounted = useMounted()
  const [isLoading, setIsLoading] = useState(true)
  const [checkInStatus, setCheckInStatus] = useState({
    screen_checked: false,
    survey_checked: false,
  })
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    qr_code: '',
  })

  const { first_name, last_name, qr_code } = user
  const { screen_checked, survey_checked } = checkInStatus
  const fullName = `${first_name} ${last_name}`

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const userData = await getProfile()
        const checkInStatus = await getCheckInStatus()
        if (isMounted.current) {
          setUser(userData)
          setCheckInStatus(checkInStatus)
        }
      } catch (error) {
        console.error(error)
        Bugsnag.notify(error)
      } finally {
        isMounted.current && setIsLoading(false)
      }
    }
    fetchData()
  }, [isMounted])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="standard-page my-badge">
      <div className="container">
        <AppNavbar
          title={`${fullName}'s QR Code Badge`}
          subtitle="Scan this QR Badge to initiate temperature scan"
        />
      </div>
      <div className="container">
        <div className="qrcode-image">
          {qr_code ? (
            <img
              alt="QR Code"
              src={`data:image/svg+xml;utf8,${encodeURIComponent(qr_code)}`}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div className="check-in-status">
          <div className="check-in-status__item">
            <img src={survey_checked ? greenTickSvg : symptomCheckSvg} alt="" />
            <p className="check-in-status__item__title">Symptom Check</p>
            {survey_checked && (
              <p className="check-in-status__item__completed">Completed</p>
            )}
          </div>
          <div className="check-in-status__item">
            <img
              src={screen_checked ? greenTickSvg : temperatureScanSvg}
              alt=""
            />
            <p className="check-in-status__item__title">Temperature Scan</p>
            {screen_checked && (
              <p className="check-in-status__item__completed">Completed</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default MyBadge
