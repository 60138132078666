/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
export function HamburgerBar() {
  const [open, setOpen] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(false)

  useEffect(() => {
    const enabled = Cookies.get('auto_refresh_enabled')
    if (enabled === undefined) {
      Cookies.set('auto_refresh_enabled', '0')
      setAutoRefresh(false)
    } else {
      setAutoRefresh(Boolean(parseInt(enabled)))
    }
  }, [])

  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh)
    Cookies.set('auto_refresh_enabled', !autoRefresh ? '1' : '0')
  }

  return (
    <>
      <nav className="ham-nav navbar navbar-light fixed-top">
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsingNavbar1"
          onClick={() => setOpen(!open)}
        >
          ☰
        </button>
        <div className="navbar-collapse collapse" id="collapsingNavbar1">
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Link
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className={open ? 'settings-tooltip' : 'close-tooltip'}>
        Auto Refresh
        <div className="slide">
          <div className="toggle">
            <input
              type="checkbox"
              id="toggle-slide"
              checked={!autoRefresh}
              onChange={() => toggleAutoRefresh()}
            />
            <label htmlFor="toggle-slide"></label>
          </div>
        </div>
      </div>
    </>
  )
}
