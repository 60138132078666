import React from 'react'

const AppNavbar = ({ style, title, subtitle, button, buttonIcon, onClick }) => {
  return (
    <div className="app-navbar pt-2 pb-3" style={style}>
      <div>
        <h1 style={{ fontWeight: '700' }}>{title}</h1>
        {subtitle && <h4>{subtitle}</h4>}
      </div>
      {button && (
        <button className="btn btn-primary" onClick={onClick}>
          {buttonIcon && <img alt={button} src={buttonIcon} />}
          {button}
        </button>
      )}
    </div>
  )
}

export default AppNavbar
