import localstore from 'store2'

const TOKEN_KEY = 'livmote_screenmein_token'
const ROLE_KEY = 'livmote_screenmein_role'

const ROLE = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
  ORG_ADMIN: 'org_admin',
}

export function getTokenWithExpires() {
  return localstore(TOKEN_KEY)
}

export function setTokenWithExpires(data) {
  return localstore(TOKEN_KEY, data)
}

export function removeTokenWithExpires() {
  localstore.remove(TOKEN_KEY)
}

export function getRole() {
  return localstore(ROLE_KEY)
}

export function setRole(data) {
  return localstore(ROLE_KEY, data)
}

export function removeRole() {
  localstore.remove(ROLE_KEY)
}

export function clearCredentials() {
  removeRole()
  removeTokenWithExpires()
}

export function isAdmin() {
  const localRole = getRole()
  return (
    localRole === ROLE.ADMIN ||
    localRole === ROLE.ORG_ADMIN ||
    localRole === ROLE.SUPER_ADMIN
  )
}

export function isOrgAdmin() {
  const localRole = getRole()
  return localRole === ROLE.ORG_ADMIN || localRole === ROLE.SUPER_ADMIN
}
