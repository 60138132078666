import request from '../utils/api'

export function getCurrentSurvey() {
  return request({
    url: '/v0/survey-template/current',
    method: 'get',
  })
}

export function submitSurvey(data) {
  return request({
    url: '/v0/survey',
    method: 'post',
    data,
  })
}

export function getSurveyResults(surveyId) {
  return request({
    url: `/v0/survey/${surveyId}`,
    method: 'get',
  })
}

export function dismissSurveyAlert(id, data) {
  return request({
    url: `/v0/survey-alert/${id}`,
    method: 'patch',
    data,
  })
}

export default {
  getCurrentSurvey,
  submitSurvey,
  getSurveyResults,
  dismissSurveyAlert,
}
