import React from 'react'
import GoBack from '../components/GoBack'
import { Link } from 'react-router-dom'

import greenTick from '../assets/green-tick.svg'
import warning from '../assets/warning.svg'

const AuthActionResultPage = props => {
  const { title, subtitle } = props

  return (
    <div className="container results-page d-flex">
      <div className="d-flex flex-column" style={{ minHeight: '100%' }}>
        {props.success ? (
          <GoBack text="Back to home" to="/login" />
        ) : (
          <GoBack />
        )}

        <div className="mb-3 mb-2 mt-5" style={{ flexGrow: 1 }}>
          {props.success ? (
            <img alt="Success" src={greenTick} />
          ) : (
            <img alt="Warning" src={warning} />
          )}
          <h1>{title}</h1>
          <h4>{subtitle}</h4>
        </div>
        <Link to="/login" className="forgot-passsword-link mt-2">
          Sign in
        </Link>
      </div>
    </div>
  )
}

export default AuthActionResultPage
