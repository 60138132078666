import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import { track } from '../utils/mixpanel'
import { useMounted } from '../utils/hooks'
import Question from '../components/Question'
import Loading from '../components/Loading'
import AppNavbar from '../components/AppNavbar'
import { getCurrentSurvey, submitSurvey } from '../api/survey'

const DailyScreen = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const [responses, setResponses] = useState([])
  const [errors, setErrors] = useState([])
  const [apiError, setApiError] = useState()
  const [hasTriedSubmit, setHasTriedSubmit] = useState(false)
  const [templateId, setTemplateId] = useState()
  const [questions, setQuestions] = useState([])
  const [screenDisplayName, setScreenDisplayName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setApiError(null)
      try {
        let data = await getCurrentSurvey()
        if (!data || data.error) {
          // logout({
          //   returnTo: `${process.env.REACT_APP_BASE_URL}/not-authorized`
          // });
          setApiError(data ? data.error : 'There was an error fetching data.')
        } else {
          track('Staff Questionnaire - Landed')
          if (isMounted.current) {
            setTemplateId(data.id)
            setQuestions(data.survey_questions)
            setScreenDisplayName(data.name)
          }
        }
      } catch (error) {
        Bugsnag.notify(error)
        isMounted.current &&
          setApiError(
            'There was an error loading data. Please refresh the page.'
          )
      }
    }
    fetchData()
  }, [isMounted])

  if (questions.length === 0 || isLoading) {
    return <Loading />
  }

  const onAnswer = (id, ans, i) => {
    let respCopy = [...responses]
    respCopy[i] = {
      survey_question_id: id,
      answer: ans,
    }
    setResponses(respCopy)
    if (hasTriedSubmit) {
      validateNoEmpty(respCopy) // Cheap way to avoid having to do useEffect
    }
  }

  const validateNoEmpty = respCopy => {
    let localResp = respCopy || responses
    let errorsCopy = []
    for (let i = 0; i < questions.length; i++) {
      if (!localResp[i]) {
        errorsCopy[i] = true
        track('Staff Questionnaire - Tried to Submit with Blank')
      }
    }
    setErrors(errorsCopy)
    return !errorsCopy.some(x => x)
  }
  // Require submit all
  const handleSubmit = async e => {
    e.preventDefault()
    setHasTriedSubmit(true)

    if (validateNoEmpty()) {
      if (isMounted.current) {
        setIsLoading(true)
        setApiError(null)
      }

      try {
        const result = await submitSurvey({
          template_id: templateId,
          payload: responses,
        })
        track('Staff Questionnaire - Submitted')
        history.push({
          pathname: `/daily-results/${result.id}`,
        })
      } catch (error) {
        Bugsnag.notify(error)
        isMounted.current && setApiError(error)
      } finally {
        isMounted.current && setIsLoading(false)
      }
    }
  }

  return (
    <div className="container standard-page">
      <AppNavbar
        title="Your Daily Screen"
        subtitle={`Following ${screenDisplayName}`}
      />
      {apiError && (
        <div className="alert alert-danger" role="alert">
          {apiError}
        </div>
      )}
      <div>
        <form onSubmit={handleSubmit}>
          {questions.map((q, i) => (
            <Question
              key={i}
              {...q}
              i={i}
              onAnswer={onAnswer}
              hasError={errors[i]}
            />
          ))}
          <div>
            <button
              className="btn btn-primary mb-4 btn-screen-submit btn-block"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DailyScreen
