/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Bugsnag from '@bugsnag/js'
import { useHistory, useLocation } from 'react-router-dom'
import { emailRegex } from '../utils/regex'
import { track } from '../utils/mixpanel'
import { isPhoneValidWithCountryCode } from '../utils/phoneParser'
import { useMounted } from '../utils/hooks'
import { getGuestTemplate, createGuestSurvey } from '../api/guest'
import { getCountryList } from '../api/country'
import { HOME_PAGE_LINK } from '../constants/link'
import Question from '../components/Question'
import Loading from '../components/Loading'
import { COUNTRY_CODE } from '../constants'

import './GuestSymptomCheck.scss'
import { HamburgerBar } from './HamburgerBar'

const demoTokens = [
  {
    name: 'Costa Mesa',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6MjYsImlhdCI6MTYwMTM1Mjc4Nn0.QKsRJuR_b7p8vj8eHCILpfHpsU1bAzEKc0Fl7qdfBZ4',
  },
  {
    name: 'Littleton',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6NDEsImlhdCI6MTYwMTM5MjA2Mn0.7tMcl6pyoufyIRI208_0IyC35Iv06v4Qg6wTnm2FjE0',
  },
  {
    name: 'Old Bridge',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6NDIsImlhdCI6MTYwMTM5MjE3N30.CleVnDtSNUcltRz2EQIGELnF8fSdeZVdg-nHljTBjKg',
  },
]

const getIsDemo = token => {
  if (!token) return false
  const matched = demoTokens.find(location => location.token === token)
  return !!matched
}

const GuestSymptomCheck = () => {
  const formRef = useRef()
  const isMounted = useMounted()
  const [surveyWithAns, setSurveyWithAns] = useState([])
  const [errors, setErrors] = useState([])
  const [apiError, setApiError] = useState()
  const [hasTriedSubmit, setHasTriedSubmit] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODE.US)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [templateId, setTemplateId] = useState()
  const [questions, setQuestions] = useState([])
  const [screenDisplayName, setScreenDisplayName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [locationName, setLocationName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const { token } = getParams(location.search)

  const isDemo = getIsDemo(token)

  useEffect(() => {
    async function fetchData() {
      setApiError(null)
      if (!token) {
        window.location.href = HOME_PAGE_LINK
      }
      try {
        setIsLoading(true)
        const data = await getGuestTemplate({ token })
        const countryList = await getCountryList()
        if (isMounted.current) {
          if (!data || data.error) {
            setApiError(data ? data.error : 'There was an error fetching data.')
          } else {
            track('Staff Questionnaire - Landed')
            setTemplateId(data.id)
            setQuestions(data.survey_questions)
            setScreenDisplayName(data.name)
            setOrganizationName(data.organization_name || 'ScreenMeIn')
            setLocationName(data.location_name)
          }
          setCountryOptions(countryList)
        }
      } catch (error) {
        Bugsnag.notify(error)
        isMounted.current &&
          setApiError(
            'Unable to generate Symptom Check Form due to invalid token.'
          )
      } finally {
        isMounted.current && setIsLoading(false)
      }
    }
    fetchData()
  }, [history, isMounted, token])

  const onAnswer = (id, ans, i) => {
    let respCopy = [...surveyWithAns]
    respCopy[i] = {
      survey_question_id: id,
      answer: ans,
    }
    setSurveyWithAns(respCopy)
    if (hasTriedSubmit) {
      validateNoEmpty(respCopy) // Cheap way to avoid having to do useEffect
    }
  }

  const validateNoEmpty = respCopy => {
    let localResp = respCopy || surveyWithAns
    let errorsCopy = []
    for (let i = 0; i < questions.length; i++) {
      if (!localResp[i]) {
        errorsCopy[i] = true
        track('Staff Questionnaire - Tried to Submit with Blank')
      }
    }
    setErrors(errorsCopy)
    return !errorsCopy.some(x => x)
  }

  const validateFirstName = () => {
    if (!formRef.current) return
    const firstNameInput = formRef.current.querySelector('#firstName')
    if (firstName.length === 0) {
      firstNameInput.classList.add('is-invalid')
      return false
    }
    firstNameInput.classList.remove('is-invalid')
    return true
  }

  const validateEmail = () => {
    if (!formRef.current) return
    const emailInput = formRef.current.querySelector('#email')

    // email is optional
    if (email.length === 0) {
      emailInput.classList.remove('is-invalid')
      return true
    }

    const isValid = emailRegex.test(email)
    if (!isValid) {
      emailInput.classList.add('is-invalid')
      return false
    }
    emailInput.classList.remove('is-invalid')
    return true
  }

  const validatePhoneNumber = countryId => {
    if (!formRef.current) return
    const phoneNumberContainer = formRef.current.querySelector(
      '.phone-number-container'
    )
    const phoneNumberInput = formRef.current.querySelector('#phone-number')

    // phone number is optional
    if (phoneNumber.length === 0) {
      phoneNumberInput.classList.remove('is-invalid')
      phoneNumberContainer.classList.remove('is-invalid')
      return true
    }
    const ISOCode = getCountryISOCode(countryId, countryOptions)
    const isValid = isPhoneValidWithCountryCode(ISOCode, phoneNumber)
    if (!isValid) {
      phoneNumberInput.classList.add('is-invalid')
      phoneNumberContainer.classList.add('is-invalid')
      return false
    }
    phoneNumberInput.classList.remove('is-invalid')
    phoneNumberContainer.classList.remove('is-invalid')
    return true
  }

  const getCountryISOCode = (id, countries) => {
    const country = countries.find(country => country.id === id)
    if (!country) return ''
    return country.iso_code
  }

  const handleCountryChange = e => {
    const countryId = Number(e.target.value)
    setSelectedCountry(countryId)
    validatePhoneNumber(countryId)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()

    setHasTriedSubmit(true)

    const validationResult = [
      validateFirstName(),
      validateEmail(),
      validatePhoneNumber(selectedCountry),
      validateNoEmpty(),
    ]

    if (validationResult.some(result => !result)) return

    setIsLoading(true)
    setApiError(null)

    try {
      const result = await createGuestSurvey({
        token,
        user: {
          phone_number: phoneNumber,
          email: email || undefined,
          first_name: firstName,
          last_name: lastName,
          phone_country_id: selectedCountry,
          guest_company_name: companyName,
        },
        survey: surveyWithAns,
        template_id: templateId,
      })
      track('Guest Questionnaire - Submitted')
      setIsLoading(false)
      history.push({
        pathname: `/guest-symptom-check/${result.id}`,
        search: `?token=${result.token}`,
      })
    } catch (error) {
      setIsLoading(false)
      Bugsnag.notify(error)
      setApiError(error)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="guest-symptom-check">
      <HamburgerBar />
      <div className="bg-light">
        <div className="container standard-page">
          <div className="app-navbar pt-2 pb-3">
            <div>
              <h1>{`${organizationName} Guest Screening`}</h1>
              <h4 className="text-secondary mb-1 font-weight-normal">
                {locationName}
              </h4>
              <h4>{`Following ${screenDisplayName}`}</h4>
              {isDemo && (
                <a href="https://livmote.com/davita-locations?reset">
                  Select Different Location
                </a>
              )}
            </div>
          </div>
          <hr className="mt-0" />
          {apiError && (
            <div className="alert alert-danger" role="alert">
              {apiError}
            </div>
          )}
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col">
                <div className="form-group required">
                  <label htmlFor="firstName" className="col-form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First name"
                    id="firstName"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    onBlur={validateFirstName}
                  />
                  <div className="invalid-feedback">First name is required</div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last name"
                    id="lastName"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="company-name" className="col-form-label">
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                id="company-name"
                placeholder="Your company name"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="col-form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onBlur={validateEmail}
              />
              <div className="invalid-feedback">Invalid email</div>
            </div>
            <div className="form-group">
              <label htmlFor="phone-number" className="col-form-label">
                Phone Number
              </label>
              <div className="input-group phone-number-container">
                <select
                  className="custom-select country-code-select"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  {countryOptions.map(({ iso_code, id, code }) => (
                    <option key={id} value={id}>
                      {iso_code} (+{code})
                    </option>
                  ))}
                </select>
                <div className="input-group-append">
                  <input
                    type="text"
                    className="form-control"
                    id="phone-number"
                    placeholder="Your phone number"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    onBlur={() => validatePhoneNumber(selectedCountry)}
                  />
                </div>
              </div>
              <div className="invalid-feedback">Invalid phone number</div>
            </div>
            {questions.map((q, i) => (
              <div key={i} className="question-container">
                <Question
                  {...q}
                  i={i}
                  colorsOnInitial={true}
                  onAnswer={onAnswer}
                  hasError={errors[i]}
                />
              </div>
            ))}
            <div>
              <button
                className="btn btn-primary mb-4 btn-screen-submit btn-block"
                type="submit"
                disabled={!!apiError}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const getParams = search => {
  return Object.fromEntries(new URLSearchParams(search))
}

export default GuestSymptomCheck
