import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import { verifyEmail, resendVerificationEmail } from '../api/auth'

const VerifyAccount = props => {
  const history = useHistory()
  const [error, setError] = useState()
  const [isResending, setIsResending] = useState(false)
  const [isVerifying, setIsVerifying] = useState(true)
  const [resendResult, setResendResult] = useState()
  const [verified, setVerified] = useState()

  const verifyToken = new URLSearchParams(props.location.search).get('token')
  const email =
    new URLSearchParams(props.location.search).get('email') &&
    decodeURIComponent(new URLSearchParams(props.location.search).get('email'))

  useEffect(() => {
    async function confirmToken() {
      setIsVerifying(true)
      setError(null)
      const payload = {
        token: verifyToken,
        email: email,
      }
      try {
        const result = await verifyEmail(payload.token)
        if (result && (result.error || result.type === 'already-verified')) {
          setVerified(result.type === 'already-verified')
          setError(result.error)
        } else {
          setVerified(true)
        }
      } catch (error) {
        console.error(error)
        setError(error)
        setVerified(false)
      }
      setIsVerifying(false)
    }

    if (verifyToken && email) {
      confirmToken()
    } else {
      history.replace('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSendTokenClicked = () => {
    async function resentToken() {
      setIsResending(true)
      setError(null)

      try {
        const result = await resendVerificationEmail(email)

        if (result.error) {
          setError(result.error)
          setVerified(result.type === 'already-verified')
        } else {
          setResendResult(result.message)
        }
      } catch (error) {
        console.error(error)
        setError(error)
        setResendResult('Unable to send token. Please try again.')
      }
      setIsResending(false)
    }
    resentToken()
  }

  const renderVerified = () => {
    return (
      <>
        <p>The account has been verified. Please log in.</p>
        <button
          className="btn btn-primary btn-screen-submit btn-block"
          onClick={() => history.replace('/login')}
        >
          Go to login
        </button>
      </>
    )
  }

  const renderVerificationTokenExpired = () => {
    return (
      <>
        <p>
          Your verification token has expired. Do you want to send a new token
          to {email}?
        </p>
        <button
          className="btn btn-primary btn-screen-submit btn-block"
          onClick={handleSendTokenClicked}
        >
          Send new token
        </button>
      </>
    )
  }

  const renderResendResult = () => {
    return (
      <>
        <p>{resendResult}</p>
        <button
          className="btn btn-primary btn-screen-submit btn-block"
          onClick={() => history.replace('/login')}
        >
          Go to login
        </button>
      </>
    )
  }

  if (isVerifying || isResending) {
    return <Loading />
  }

  return (
    <div className="container standard-page">
      <AppNavbar title="Verify Account" />
      <div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <div className="py-3">
          {(() => {
            if (resendResult) {
              return renderResendResult()
            } else if (verified) {
              return renderVerified()
            } else {
              return renderVerificationTokenExpired()
            }
          })()}
        </div>
      </div>
    </div>
  )
}

export default VerifyAccount
