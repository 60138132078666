import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import SetUpPasswordForm from '../components/SetUpPasswordForm'
import { clearCredentials } from '../utils/auth'
import { resetPassword } from '../api/auth'
import { sendWelcomeMail } from '../api/mail'

const ChangePassword = props => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const resetToken = new URLSearchParams(props.location.search).get(
    'resetToken'
  )

  const handleSubmit = async ({ password, passwordConfirm }, e) => {
    e.preventDefault()
    if (password !== passwordConfirm) {
      setError("Passwords don't match.")
      return
    }
    setIsLoading(true)
    try {
      await resetPassword({ token: resetToken, password })
      await sendWelcomeMail(resetToken)
      clearCredentials()
      history.push('/change-password-success')
    } catch (error) {
      Bugsnag.notify(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="container standard-page">
      <AppNavbar title="Set Up Password" />
      <div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <SetUpPasswordForm token={resetToken} handleSubmit={handleSubmit} />
      </div>
    </div>
  )
}

export default ChangePassword
