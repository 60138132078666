// polyfill ES6 methods to support old browsers when combile.
// polyfill must be in the first line
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import moment from 'moment'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { releaseStage } from './configs'
import { StateProvider } from './store'

import './livemote.scss'

const rStage = releaseStage()
if (rStage !== 'production') {
  console.log(`Running in ${rStage}`)
  console.log('App Version', process.env.REACT_APP_VERSION || '0.0.0')
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'no-op',
  appVersion: process.env.REACT_APP_VERSION || '0.0.0',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: rStage,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

moment.updateLocale('en', {
  calendar: {
    lastDay: 'D MMMM HH:mm',
    sameDay: '[Today at] LT',
    nextDay: 'D MMMM HH:mm',
    lastWeek: 'D MMMM HH:mm',
    nextWeek: 'D MMMM HH:mm',
    sameElse: 'D MMMM HH:mm',
  },
})

ReactDOM.render(
  <ErrorBoundary>
    <StateProvider>
      <App />
    </StateProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
