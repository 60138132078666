import React, { useState } from 'react'
import { PASSWORD_MIN_LENGTH } from '../constants'

const ChangePasswordForm = ({ handleSubmit, token }) => {
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const hasTokenInParam = token ? true : false
  return (
    <form
      onSubmit={event =>
        handleSubmit({ oldPassword, password, passwordConfirm }, event)
      }
    >
      {!hasTokenInParam ? (
        <div className="form-group">
          <label htmlFor="old_password">Old Password</label>
          <input
            className="form-control"
            type="password"
            name="old_password"
            id="old_password"
            required
            onInput={e => setOldPassword(e.target.value)}
          />
        </div>
      ) : (
        ''
      )}
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          className="form-control"
          type="password"
          name="password"
          id="password"
          required
          minLength={PASSWORD_MIN_LENGTH}
          onInput={e => setPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="passwordd_confirm">Password Confirmation</label>
        <input
          className="form-control"
          type="password"
          name="password_confirm"
          id="password_confirm"
          required
          onInput={e => setPasswordConfirm(e.target.value)}
        />
      </div>
      <button
        type="submit"
        className="btn btn-primary btn-block btn-screen-submit"
      >
        Submit
      </button>
    </form>
  )
}

export default ChangePasswordForm
