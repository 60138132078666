import mixpanel from 'mixpanel-browser'
import { releaseStage } from '../configs'
mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY)

export const identify = id => {
  if (releaseStage() === 'production' || releaseStage() === 'staging') {
    mixpanel.identify(id)
  } else {
    console.log(`mixpanel.identify(${id})`)
  }
}

export const alias = id => {
  if (releaseStage() === 'production' || releaseStage() === 'staging') {
    mixpanel.alias(id)
  } else {
    console.log(`mixpanel.alias(${id})`)
  }
}

export const track = (name, props) => {
  if (releaseStage() === 'production' || releaseStage() === 'staging') {
    mixpanel.track(name, props)
  } else {
    console.log(`mixpanel.track(${name}, ${props})`)
  }
}

export const people = props => {
  if (releaseStage() === 'production' || releaseStage() === 'staging') {
    mixpanel.people.set(props)
  } else {
    console.log(`mixpanel.people.set(${props})`)
  }
}
