import React, { Fragment } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { getTokenWithExpires, isAdmin } from '../utils/auth'

import HomeImg from '../assets/home-illustration.svg'

import { track } from '../utils/mixpanel'

const Home = () => {
  track('User on Home Screen')
  if (getTokenWithExpires()) {
    if (isAdmin()) {
      track('Admin Login')
    } else {
      track('Staff Login')
    }
    return <Redirect to="/daily-screen" />
  }

  return (
    <Fragment>
      <div className="jumbotron jumbotron-fluid splash">
        <div className="container-fluid">
          <h1 style={{ fontWeight: 'bold' }}> Daily Check </h1>
          <h2> Complete in seconds! </h2>
          <img alt="Daily Screen App" className="mt-4" src={HomeImg} />
        </div>
      </div>
      <div className="container-fluid pb-4" style={{ textAlign: 'center' }}>
        <Link to="/login">
          <button
            id="qsLoginBtn"
            className="btn btn-primary btn-margin btn-screen-submit btn-block mx-auto"
            style={{ width: '80%' }}
          >
            Login
          </button>
        </Link>
      </div>
    </Fragment>
  )
}

export default Home
