import request from '../utils/api'

// Login
export function login(payload) {
  const loginPromise = request({
    url: '/v0/auth/login',
    method: 'post',
    data: payload,
  })

  return loginPromise.then(async ({ token, expires_in }) => {
    const userResult = await request({
      url: '/v0/auth/me',
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return { token, expires_in, user: userResult }
  })
}

// Send an email to reset password
export function forgotPassword(email) {
  const payload = { email }
  return request({
    url: '/v0/auth/reset-password/mail',
    method: 'post',
    data: payload,
  })
}

// Reset password with token
export function resetPassword(payload) {
  return request({
    url: '/v0/auth/reset-password',
    method: 'patch',
    data: payload,
  })
}

// Verfiy email after user sign up
export function verifyEmail(verificationToken) {
  const payload = { token: verificationToken }
  return request({
    url: '/v0/auth/email-verification',
    method: 'post',
    data: payload,
  })
}

// Resend verfication email
export function resendVerificationEmail(email) {
  const payload = { email }
  return request({
    url: '/v0/auth/email-resend',
    method: 'post',
    data: payload,
  })
}

export default {
  login,
  forgotPassword,
  resetPassword,
  verifyEmail,
  resendVerificationEmail,
}
