import React from 'react'
import { Link } from 'react-router-dom'

const SignInForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
        className="form-control"
        type="email"
        name="email"
        id="email"
        placeholder="Email"
        required
      />
    </div>
    <div className="form-group">
      <label htmlFor="phone">Password</label>
      <input
        className="form-control"
        type="password"
        name="password"
        id="password"
        placeholder="Password"
        required
      />
    </div>
    <Link
      to="/reset-password"
      className="forgot-password-link"
      style={{ fontSize: '13px' }}
    >
      Forgot password?
    </Link>
    <button
      type="submit"
      className="btn btn-primary mt-5 btn-block btn-screen-submit"
    >
      Login
    </button>
  </form>
)

export default SignInForm
