import request from '../utils/api'

export function getGuestTemplate({ token }) {
  return request({
    url: `/v0/guest/survey-template/current`,
    method: 'get',
    params: { token },
  })
}

export function createGuestSurvey(data) {
  return request({
    url: '/v0/guest/survey',
    method: 'post',
    data,
  })
}

export function getGuestSurvey({ id, token }) {
  return request({
    url: `/v0/guest/survey/${id}`,
    method: 'get',
    params: { token },
  })
}

export function getGuestQRCode({ id, token }) {
  return request({
    url: `/v0/guest/${id}/qrcode`,
    method: 'get',
    params: { token },
  })
}

export function createManualScreen(token, temperature) {
  return request({
    url: '/v0/guest/manual-screen',
    method: 'post',
    data: { token, temperature },
  })
}
