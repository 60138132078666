import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import PrivateRoute from './components/PrivateRoute'
import BottomTabBar from './components/BottomTabBar'

import SignIn from './views/SignIn'
import ChangePassword from './views/ChangePassword'
import SetUpPassword from './views/SetUpPassword'
import AccountPage from './views/AccountPage'
import ResetPassword from './views/ResetPassword'
import VerifyAccount from './views/VerifyAccount'
import AuthActionResultPage from './views/AuthActionResultPage'
import Home from './views/Home'
import DailyScreen from './views/DailyScreen'
import DailyResults from './views/DailyResults'
import Badge from './views/MyBadge'
import NotAuthorized from './views/NotAuthorized'
import GuestSymptomCheck from './views/GuestSymptomCheck'
import GuestSymptomCheckResult from './views/GuestSymptomCheckResult'
import PageNotFound from './views/PageNotFound'
import history from './utils/history'

// styles
import './App.css'

// fontawesome
import initFontAwesome from './utils/initFontAwesome'
initFontAwesome()

const App = () => {
  return (
    <Router history={history}>
      <div id="app">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/not-authorized" component={NotAuthorized} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/setup-password" component={SetUpPassword} />
          <Route
            exact
            path="/guest-symptom-check"
            component={GuestSymptomCheck}
          />
          <Route
            exact
            path="/guest-symptom-check/:id"
            component={GuestSymptomCheckResult}
          />
          <Route
            exact
            path="/change-password-success"
            render={props => (
              <AuthActionResultPage
                {...props}
                success={true}
                title={'Your password change has been successful'}
                subtitle={'You are able to now log in with your new password.'}
              />
            )}
          />
          <Route exact path="/verify" component={VerifyAccount} />
          <Route path="/reset-password" component={ResetPassword} />
          <PrivateRoute path="/daily-screen" component={DailyScreen} />
          <PrivateRoute path="/daily-results/:id" component={DailyResults} />
          <PrivateRoute path="/my-badge" component={Badge} />
          <PrivateRoute path="/account" component={AccountPage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
        <BottomTabBar />
      </div>
    </Router>
  )
}

export default App
