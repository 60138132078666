import React from 'react'

function PageNotFound() {
  return (
    <div className="container standard-page">
      <div className="app-navbar">
        <h1>Sorry, this page isn't available.</h1>
      </div>

      <p>
        The link you followed may be broken, or the page may have been removed.
      </p>
      <a href="https://app.screenmein.com">Go back to ScreenMeIn</a>
    </div>
  )
}

export default PageNotFound
