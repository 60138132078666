import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactMarkdown from 'react-markdown'

const EULAModal = ({ isOpen, onDecline, onAgree }) => (
  <Modal isOpen={isOpen} className="eula-modal" centered scrollable={true}>
    <ModalHeader>End User License Agreement</ModalHeader>
    <ModalBody>
      <ReactMarkdown>{EULA}</ReactMarkdown>
    </ModalBody>
    <ModalFooter>
      <button className="btn btn-primary" onClick={onAgree}>
        Accept
      </button>
      <button className="btn btn-secondary" onClick={onDecline}>
        Decline
      </button>
    </ModalFooter>
  </Modal>
)

const EULA = `
**END USER LICENSE AGREEMENT**

**BY ORDERING OR USING THIS PRODUCT OR CLICKING TO AGREE TO THIS END USER LICENSE AGREEMENT, YOU AGREE TO THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT ("AGREEMENT"). YOU WILL BE REFERRED TO AS THE "CUSTOMER." CUSTOMER ACKNOWLEDGES THAT CUSTOMER HAS READ AND AGREES TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT WITH RETAIL HARDWARE SOLUTIONS, LLC ("LICENSOR"). CUSTOMER AGREES TO THE TERMS OF THIS AGREEMENT ON BEHALF OF ITS ENTITY FOR WHICH CUSTOMER IS AN AUTHORIZED REPRESENTATIVE AND ON BEHALF OF ALL USERS UNDER CUSTOMER'S ACCOUNT. THE DATE THAT CUSTOMER AGREES TO THIS AGREEMENT IS THE "EFFECTIVE DATE."**

**1. SERVICES**

1.1 Definitions.

a. "Device(s)" means the LivMote hardware Device(s) provided to Customer that Customer orders from Licensor or an authorized Licensor reseller and that are used for touchless temperature and face mask checks, and user check in.

b. "Fees" means the fees due and payable by Customer to Licensor or a Licensor authorized reseller.

c. "Product" means collectively and individually, the Services and the Device(s).

d. "Services" means Licensor's proprietary (a) the LivMote hosted portal, which includes the online dashboard and portal for screenings, including temperature checks and (b) ScreenMeIn Web App, which is a mobile health check app that includes COVID-19 screening questions.

e. "End User(s)" means any individual that uses the Product or Services provided by the Licensor, including, but not limited to, employees, contractors, and visitors of the Customer.

1.2 License to Services. Subject to the terms of this Agreement, Licensor hereby grants to Customer a non-exclusive, non-transferable, personal license during the Agreement term to access and use the Services for Customer's internal business use and for the applicable term as set forth in Section 4.1 or which Customer has paid.

1.3 Licensor's Obligations. Licensor shall provide the Services for the applicable term as set forth in Section 4.1. Licensor, at its option and from time to time, may modify the Services, (e.g. to provide new features, implement new protocols, maintain compatibility with emerging standards or comply with regulatory requirements); provided, however, that the functionality of any Services will not be materially decreased from that available as of the Effective Date or otherwise pursuant to an amendment to this Agreement.

**2. PAYMENT TERMS**

Customer agrees to pay the applicable Fees to Licensor or Licensor's authorized reseller, as applicable, according to the separate agreement between Licensor and Customer or Licensor's authorized reseller and Customer, as applicable.

**3. CUSTOMER DATA**

3.1 Use of Customer's Customer Aggregated Data. "Customer Data" is any data, including personal data, that is input into the Services by Customer or that is collected by or through the Services. Licensor may provide, collect, use and distribute statistical information related to the usage of the Services such as usage or traffic patterns only in aggregate form to third parties, but such information will not include identifiable information of Customer or Customer's customers or personally identifying information for Customer's employees or authorized contractors. Licensor may, from time to time, use or make available aggregate Customer Data that is not personally identifiable based upon information learned during the course of its performance of this Agreement. Licensor's use of personally identifiable information will be in accordance with the terms of Licensor's published privacy policy.

3.2 Feedback. Customer may provide Licensor with oral feedback and/or written feedback related to Customer's use of the Services, including, reports, materials, information, ideas, concepts, feedback and know-how concerning the Services and any information reported automatically through the Services to Licensor ("Feedback"). Customer hereby grants to Licensor a worldwide, perpetual, irrevocable license to use, reproduce, modify, publicly perform, publicly display, distribute, sell, offer to sell and exploit in any manner any Feedback.

**4. TERM AND TERMINATION**

4.1 Term. The term of Agreement shall commence upon the Effective Date and shall continue for the term for which Customer has paid the applicable Fees to Licensor or Licensor's authorized reseller, as applicable. Customer may terminate this Agreement at any time by ceasing use of the Services.

4.2 Termination and Suspension. This Agreement may be terminated by either party for cause immediately by written notice upon the occurrence of any of the following events: (a) if the other ceases to do business; (b) if the other breaches any material provision of this Agreement and fails to cure such breach within thirty (30) days of its receipt of written notice from the non-breaching party specifying the nature of the breach in reasonable detail; or (c) if the other becomes insolvent or seeks protection under any bankruptcy, receivership, trust, deed, creditor's arrangement, or comparable proceeding, or if any such proceeding is instituted against the other and not dismissed within sixty (60) days. In addition to other rights and remedies it may have, Licensor, without liability to Customer, reserves the right to suspend Services usage if Customer has breached any provisions of this Agreement until Customer is no longer in breach.

4.3 Other Termination by Licensor. If Licensor determines that a law or regulatory action prohibits, substantially impairs or makes impractical the provision of any Services, Licensor may, at its option, upon written notice to Customer and without liability, terminate the Services, or modify the affected Services, or the terms and conditions applicable to such Services, in order to conform to such law or action.

4.4 Effect of Termination. Upon termination or expiration of this Agreement, Licensor shall cease providing the Services and any support for the Services hereunder.

4.5 Survival. Sections 1.1, 3, 4.4, 4.5, 5, 6, 7, 8, 9 and 10 shall survive the expiration or termination of this Agreement.

**5. WARRANTY DISCLAIMERS**

5.1 Disclaimer. Except as expressly provided in this Agreement, or to the extent provided by licensor's authorized reseller to customer, the Device(s) and all Services provided to Customer hereunder are provided "as is" basis, and Licensor makes no representation or warranty relating thereto, including, but not limited to, the implied warranties of merchanTability, fitness for a particular purpose, or any other warranties that might arise from the course of dealing, usage or trade practice. No advice or information provided by Licensor will create a warranty. Customer assumes total responsibility for any and all risk arising from Customer's use of, or inability to use, the DEVICE(S) or the services. LICENSOR SHALL HAVE NO RESPONSIBILITY FOR LOSS OF DATA OR INABILITY TO USE THE SERVICES DUE TO THE ACTS OR OMISSIONS OF LICENSOR'S HOSTING PROVIDERS.

IF CUSTOMER PURCHASED THE DEVICE(S) FROM LICENSOR, CUSTOMER MAY CONTACT LICENSOR ACCORDING TO THE TERMS OF THE SALES AGREEMENT BETWEEN LICENSOR AND CUSTOMER. IF CUSTOMER PURCHASED THE DEVICE(S) FROM AN AUTHORIZED RESELLER, CUSTOMER MUST CONTACT LICENSOR'S AUTHORIZED RESELLER FOR ANY CLAIMS AND ISSUES REGARDING THE DEVICE(S) OR THE SERVICES.

5.2 Medical Disclaimer. LICENSOR DOES NOT REPRESENT OR WARRANT THAT THE PRODUCTS ARE FAIL SAFE, ERROR FREE OR CERTIFIED AS A MEDICAL DEVICE. THE PRODUCTS ARE NOT MEDICAL DEVICES AND SHOULD NOT BE USED TO DIAGNOSE, PREVENT OR TREAT COVID-19 OR ANY OTHER DISEASE. INFORMATION THAT IS OUTPUT FROM THE PRODUCTS ARE BASED ON CALCULATIONS GENERATED FROM READINGS FROM DEVICE SENSORS, INCLUDING A THERMOPILE INFRARED SENSOR AND A STANDARD RGB CAMERA. INFORMATION THAT IS OUTPUT FROM THE PRODUCTS MAY INDICATE THE NEED FOR FURTHER SCREENING AND SUCH INFORMATION MUST NOT BE SOLELY RELIED UPON FOR DIAGNOSIS OF COVID-19 OR ANY OTHER DISEASE.CUSTOMER IS RESPONSIBLE FOR PROPER USE, PROPER SETUP, AND PROPER ENVIRONMENT IN ORDER TO PROVIDE HIGHER DEGREES OF ACCURACY IN THE INFORMATION THAT IS OUTPUT FROM THE PRODUCTS. IF THE PRODUCTS INDICATE CUSTOMER HAS AN ELEVATED BODY TEMPERATURE, THIS SHOULD BE CONFIRMED WITH SECONDARY EVALUATION METHODS (E.G., AN NCIT OR CLINICAL GRADE CONTACT THERMOMETER). THE PRODUCTS CANNOT DETECT THE PRESENCE OF A VIRUS. IF CUSTOMER NEEDS MEDICAL ADVICE OR ASSISTANCE, CUSTOMER MUST CONTACT THEIR MEDICAL PROVIDER.

**6. INDEMNIFICATION**

Customer agrees to defend any third party claim against Licensor alleging that any Customer Data is in violation of any law, rule, or regulatory action or any privacy rights, and Customer agrees to pay any final non-appealable judgment rendered on such claim, provided Licensor gives Customer prompt notice of the claim, allows Customer sole control of the defense or settlement of the claim, and fully cooperates with Customer in such defense or settlement. Customer shall have obtained all necessary consents from individuals to upload Customer Data into the Services or to permit the Services to collect such Customer Data.

**7. LIMITATION OF LIABILITY**

7.1No Liability For Consequential [Damages.To](http://damages.to/) the maximum extent permitted by applicable law, EXCEPT FOR A BREACH OF SECTION 1 or 8 BY CUSTOMER, in no event shall either party be liable to the other forany incidental, indirect, special, exemplary damages, damages for loss of business profits, loss of business information, or other pecuniary loss arising out of the use of THE Services, even if such party has been advised of the possibility of such loss or damages. The limitations on liability and types of damages stated in this Section apply, regardless of the form of any lawsuit or claim either party may bring, whether in tort, contract or otherwise, and regardless of whether any limited remedy provided in this Agreement fails of its essential purpose.

7.2Direct Damages.EXCEPT FOR A BREACH OF SECTION 1 or 8 BY CUSTOMER OR CUSTOMER'S PAYMENT OBLIGATIONS,in no event shall EITHER PARTY'S TOTAL, AGGREGATE LIABILITY TO THE OTHER PARTY OR TO ANY THIRD PARTY FOR ANY CLAIMS OR DAMAGES ARISING UNDER THIS AGREEMENT EXCEED THE GREATER OF: ONE thousand DOLLARS ($1000.00) AND THE AMOUNTS PAID AND PAYABLE BY CUSTOMER FOR THE SERVICES DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEEDING THE CLAIM.

**8. OWNERSHIP**

8.1 Ownership. The Services are licensed to Customer for use during the term of this Agreement, not sold. There are no implied rights. All right, title and interest in and to the Services is owned by Licensor or its suppliers, and is protected by United States copyright laws and international treaty provisions. In no event shall Customer reverse engineer, modify, decompile, time share, disassemble or extract any portion of any Services provided hereunder.

8.2 Usage Requirements. Customer shall abide by all usage restrictions and guidelines provided by Licensor in using the Product. These include:

- Customer shall not dissemble the Device(s).
- Customer shall not subject the Device(s) to inclement weather or water.
- Customer shall not install the Device(s) pointed directly at the sun.
- Customer shall use the Device(s) solely indoors.
- Customer shall use each Device for only one individual at a time.
- Customer shall wait at least 45 minutes after powering on each Device before using the Device for temperature checks.
- For temperature checks, Customer shall ensure End Users are standing between 20-40cm from the Device to obtain the best results.
- For temperature checks, Customer shall ensure End Users do not have any object, including, but not limited to, hair, glasses, and hats, blocking their forehead and face to obtain the best results.
- Customer takes full responsibility for ensuring the screening questions used in the ScreenMeIn Web App are compliant to their requirements.

**9. GENERAL PROVISIONS**

9.1 Modifications/Waiver. This Agreement shall not be modified, amended or in any way altered, nor may it be modified by custom and usage of trade or course of dealing, except by an instrument in writing and signed by duly authorized officers of both of the parties hereto. Performance of any obligation required of a party hereunder may be waived only by a written waiver signed by a duly authorized officer of the other party, which waiver shall be effective only with respect to the specific obligation described therein. The waiver by either party hereto of a breach of any provision of this Agreement by the other shall not operate or be construed as a waiver of any subsequent breach of the same provision or any other provision of this Agreement.

9.2 Severability. In the event that any provision hereof is found invalid or unenforceable pursuant to judicial decree or decision, the remainder of this Agreement shall remain valid and enforceable according to its terms. Without limiting the foregoing, it is expressly understood and agreed that each and every provision of this Agreement which provides for a limitation of liability, disclaimer of warranties or exclusion of damages is intended by the parties to be severable and independent of any other provision and to be enforced as such. Further, it is expressly understood and agreed that in the event any remedy hereunder is determined to have failed of its essential purpose, all limitations of liability and exclusion of damages set forth herein shall remain in full force and effect.

9.3 Relationship. Licensor and Customer are independent contractors and nothing in this Agreement shall be construed: (a) to give either party the power to direct or control the daily activities of the other party, or (b) to constitute the parties as principal and agent, employer and employee, franchisor and franchisee, partners, joint venturers, co-owners, or otherwise as participants in a joint undertaking. Licensor and Customer understand and agree that Licensor does not grant Customer the power or authority to make or give any agreement, statement, representation, warranty or other commitment on behalf of Licensor, or to enter into any contract or otherwise incur any liability or obligation, express or implied, on behalf of Licensor, or to transfer, release or waive any right, title or interest of Licensor.

9.4 Assignment. Neither party may assign any of its rights or obligations hereunder, whether by operation of law or otherwise, without the prior express written consent of the other party. Notwithstanding the foregoing, either party may assign this Agreement together with all rights and obligations hereunder, without consent of the other party, in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets not involving a direct competitor of the other party. Any attempt by a party to assign its rights or obligations under this Agreement in breach of this section shall be void and of no effect.

9.5 Binding Agreement. Subject to Section 9.4 above, this Agreement shall be binding upon and inure to the benefit of each of the parties hereto and their respective legal successors and assigns, provided that any successor or assignee shall be bound by all the terms and conditions of this Agreement.

9.6 Governing Law. This Agreement shall be construed and enforced in accordance with the laws of the State of California applicable to agreements a wholly executed and wholly performed therein, excluding its choice of law rules based upon the jurisdiction of any legal action or proceeding. Any legal action or proceeding brought by either party against the other arising out of or related to this Agreement shall be brought only in a state or federal court of competent jurisdiction located in the counties of Los Angeles, California, and the parties hereby consent to the *in personam* jurisdiction and venue of said courts in which such matter is first filed. In any legal action or proceeding brought under this Agreement, the prevailing party will be entitled to recover its attorneys' fees and costs.

9.7 Changes.This Agreement may be changed, modified, supplemented or updated by Licensor from time to time. If Licensor makes a material change to this Agreement that will affect an existing license, Licensor will notify Customer by posting a notice on Customer's account administration page. If the change has a material adverse impact on Customer and Customer does not agree to the change, Customer must so notify Licensor at [support@livmote.com](mailto:support@livmote.com) within thirty (30) days after receiving notice of the change. If Customer notifies Licensor as required, then Customer will remain governed by this Agreement in effect immediately prior to the change until the end of Customer's current license term. If the license is renewed, it will be renewed under Licensor's then current Agreement.

9.8 Legal Advice. Each of the parties hereto represents that it has carefully read this Agreement and that it knows and understands the contents hereof. Each of the parties has received independent legal advice from attorneys of its choice with respect to the preparation, review and advisability of executing this Agreement.
`

export default EULAModal
