import React from 'react'
import './ButtonLink.scss'

const ButtonLink = ({ classNames, children, handleClick, ...restProps }) => {
  return (
    <button
      className={`button-link btn btn-link font-weight-bold p-0 ${classNames}`}
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </button>
  )
}

export default ButtonLink
