import React from 'react'
import warning from '../assets/warning.svg'
import success from '../assets/green-tick-2.svg'

const StaticQuestion = ({
  text,
  options,
  survey_result = { answer: '', flagged: false },
  i,
}) => {
  let optionsBullets

  if (Array.isArray(options) && options.length > 0) {
    optionsBullets = (
      <ul className="options-bullets">
        {options.map((o, i) => (
          <li key={i}>{o}</li>
        ))}
      </ul>
    )
  }

  return (
    <div className="question-row">
      <div>
        <strong>Q{i + 1}</strong>. {text}
        <br />
        {options && optionsBullets}
        <Answer answer={survey_result.answer} flag={survey_result.flagged} />
      </div>
    </div>
  )
}

const Answer = ({ answer, flag }) => {
  let bgColor = flag
    ? { backgroundColor: '#ff0006' }
    : { backgroundColor: '#25a452' }

  if (answer === 'yes' || answer === 'no') {
    return (
      <button
        type="button"
        className="btn btn-primary btn-yesno mt-3"
        style={bgColor}
      >
        {answer[0].toUpperCase() + answer.substring(1)}
      </button>
    )
  }
  return (
    <div className="mt-3 font-weight-bold d-flex align-align-items-center type-range">
      {answer}{' '}
      {flag ? (
        <img
          alt="Warning"
          className="ml-2"
          src={warning}
          style={{ width: '20px' }}
        />
      ) : (
        <img alt="Success" className="ml-2" src={success} />
      )}{' '}
    </div>
  )
}

export default StaticQuestion
