import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ConfirmModal = ({ title, body, isOpen, toggle, onAction }) => (
  <Modal isOpen={isOpen} toggle={toggle} centered>
    <ModalHeader toggle={toggle}>{title}</ModalHeader>
    <ModalBody>{body}</ModalBody>
    <ModalFooter>
      <button className="btn btn-secondary" onClick={toggle}>
        Cancel
      </button>{' '}
      <button className="btn btn-primary" onClick={onAction}>
        Confirm
      </button>
    </ModalFooter>
  </Modal>
)

export default ConfirmModal
