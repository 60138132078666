import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import { track } from '../utils/mixpanel'

import { login } from '../api/auth'
import { store } from '../store'

import {
  clearCredentials,
  setTokenWithExpires,
  setRole,
  isAdmin,
} from '../utils/auth'
import AppNavbar from '../components/AppNavbar'
import Loading from '../components/Loading'
import SignInForm from '../components/SignInForm'
import GoBack from '../components/GoBack'

const SignIn = () => {
  const history = useHistory()
  const { dispatch } = useContext(store)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const query = new URLSearchParams(history.location.search)
  const sessionExpired = parseInt(query.get('session_expired'), 10)

  useEffect(() => {
    clearCredentials()
  }, [])

  useEffect(() => {
    if (sessionExpired === 1) {
      setError('Session expired. Please re-login.')
    }
  }, [sessionExpired, setError])

  if (isLoading) {
    return <Loading />
  }

  const handleSubmit = async e => {
    let n = e.nativeEvent
    e.preventDefault()
    setError(null)
    setIsLoading(true)
    const data = {
      email: n.target[0].value ? n.target[0].value.toLowerCase() : undefined,
      password: n.target[1].value,
    }

    try {
      const payload = { email: data.email, password: data.password }
      const resp = await login(payload)
      const expiresAt = Date.now() / 1000 + resp.expires_in // in sec
      setTokenWithExpires({ token: resp.token, expires_at: expiresAt })
      setRole(resp.user.role.name)
      dispatch({ type: 'STORE_USER', payload: resp.user })

      setIsLoading(false)

      // A bit hacky. I promise I won't store more things in local storage.
      if (isAdmin()) {
        track('Admin Login')
      } else {
        track('Staff Login')
      }
      const from = query.get('next') || '/'
      history.push(from)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
      Bugsnag.notify(error)
      setError(error)
    }
  }
  return (
    <div className="container standard-page d-flex flex-column justify-content-between">
      <div>
        <GoBack
          doOnClick={() => {
            window.open('https://www.screenmein.com', '_self')
          }}
        />
        <AppNavbar title="Sign In" />
        <div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <SignInForm handleSubmit={handleSubmit} />
        </div>
        <div className="py-2 ">
          <small className="text-muted">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.screenmein.com/privacy"
            >
              Privacy Policy
            </a>
          </small>
        </div>
      </div>
    </div>
  )
}

export default SignIn
