import axios from 'axios'
import { getTokenWithExpires } from '../utils/auth'
import { releaseStage } from '../configs'

const apiOrigin = process.env.REACT_APP_API_URL || 'http://localhost:9527'

const RequestTimeout = 30 * 1000 // ms

function mapErrorMsg(status, message) {
  switch (status) {
    case 401:
      return 'Unauthorized. Please login again.'
    case 403:
      return 'Permission denied.'
    case 422:
      return 'Please check your input.'
    case 500:
      return 'Please contact admin.'
    default:
      return message
  }
}

// create an axios instance
const service = axios.create({
  baseURL: apiOrigin, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: RequestTimeout,
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const tokenData = getTokenWithExpires()
    if (tokenData) {
      config.headers['Authorization'] = `Bearer ${tokenData.token}`
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // only status code start with 2 considered valid
    const statusRegex = /^2\d{2}/
    if (!statusRegex.test(response.status)) {
      const defaultErrorMsg = `${response.status} - ${response.data.message}`
      const errorMsg = mapErrorMsg(response.status, defaultErrorMsg)
      return Promise.reject(new Error(errorMsg))
    } else {
      return response.data
    }
  },
  error => {
    if (!error) {
      return Promise.reject('An unknown error occured.')
    }
    if (error && !error.response) {
      return Promise.reject(error)
    }
    const { status, statusText, data } = error.response
    const defaultErrorMsg = data.message || ''

    if (releaseStage() !== 'production') {
      // for debug
      console.error(
        data.message
          ? `Error ${status} ${statusText}: ${data.message}`
          : `Error ${status} ${statusText}`
      )
    }

    if (data && data.type === 'already-verified') {
      // TODO: Handle this better, but for now, give responsibility to VerifyAccount
      return data
    }

    if (data && data.error) {
      return Promise.reject(data.error)
    }

    if (status !== 500 && data && data.message) {
      return Promise.reject(data.message)
    }

    const errorMsg = mapErrorMsg(status, defaultErrorMsg)
    return Promise.reject(errorMsg)
  }
)

export default service
