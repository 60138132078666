import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import greenTick from '../assets/green-tick.svg'
import warning from '../assets/warning.svg'
import Cookies from 'js-cookie'
import { HamburgerBar } from './HamburgerBar'
export const ResultsHeader = ({
  flagged,
  isManualInput,
  countdown,
  handlePrintBadge,
}) => {
  const [autoRefresh, setAutoRefresh] = useState(false)

  const history = useHistory()
  const goBack = () => history.goBack()

  useEffect(() => {
    const enabled = Boolean(parseInt(Cookies.get('auto_refresh_enabled')))
    setAutoRefresh(enabled)
  }, [])

  return (
    <div className="results-header mb-3 my-2 pb-3">
      <HamburgerBar />
      {!isManualInput && (
        <Row className="pb-2">
          <Col sm="6" className="pb-2">
            {!flagged && (
              <button
                className="btn btn-primary btn-screen-submit w-100"
                onClick={handlePrintBadge}
              >
                Print Badge
              </button>
            )}
          </Col>
          <Col sm="6" className="pb-2">
            <button
              className="btn btn-primary btn-screen-submit  w-100"
              onClick={goBack}
            >
              Restart Screening
            </button>
          </Col>
        </Row>
      )}
      {flagged ? (
        <div className="pb-2">
          <img alt="Warning" src={warning} />
          <h1>Your Check In Requires Attention</h1>
          <h4>Your screening requires additional attention:</h4>
          <ul>
            <li>
              If you correctly reported COVID related symptoms or possible
              exposure, please wait and a team member will come see you.
            </li>
            <li>
              If you answered a question in error,
              <button
                type="button"
                className="btn btn-link text-primary mx-2 align-baseline border-0 p-0 link"
                onClick={goBack}
              >
                Click here
              </button>
              to repeat the symptom screening process. and repeat the symptom
              screening process.
            </li>
          </ul>
        </div>
      ) : (
        <div className="d-flex align-items-center pb-2">
          <img alt="Success" src={greenTick} />
          <h1 className="flex-grow-1 ml-4 my-0">Check In Successful</h1>
        </div>
      )}
      {autoRefresh && (
        <small>Redirecting to guest symptom check screen in {countdown}</small>
      )}
    </div>
  )
}
