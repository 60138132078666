import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonLink from '../components/ButtonLink'
import BackArrow from '../assets/back-arrow.svg'

const GoBack = ({ to, text, doOnClick }) => {
  const history = useHistory()

  const handleOnClick = () => {
    if (to) {
      history.push(to)
    } else {
      history.goBack()
    }
  }

  return (
    <div className="mt-3">
      <ButtonLink
        classNames="d-inline-flex align-items-center"
        handleClick={() => {
          doOnClick ? doOnClick() : handleOnClick()
        }}
      >
        <img alt="Back" src={BackArrow}></img>
        <span className="ml-1">{text || 'Back'}</span>
      </ButtonLink>
    </div>
  )
}

export default GoBack
