import request from '../utils/api'

export function getProfile() {
  return request({
    url: '/v0/auth/me',
    method: 'get',
  })
}

export function updateProfile(data) {
  return request({
    url: '/v0/user/account',
    method: 'put',
    data,
  })
}

export function changeMyPassword(payload) {
  return request({
    url: '/v0/auth/me/password',
    method: 'patch',
    data: payload,
  })
}

export default {
  getProfile,
  changeMyPassword,
}
