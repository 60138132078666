import React, { useState } from 'react'

import './Question.scss'

const TYPE_YESNO = 1
const TYPE_NUMBER = 2
const Question = ({
  id,
  text,
  options,
  type,
  i,
  colorsOnInitial,
  validator,
  onAnswer,
  hasError,
}) => {
  const [answer, setAnswer] = useState('')
  let input
  let optionsBullets

  const handleAnswer = ans => {
    setAnswer(ans)
    if (onAnswer) {
      onAnswer(id, ans, i)
    }
  }

  if (type === TYPE_YESNO) {
    input = (
      <YesNo
        colorsOnInitial={colorsOnInitial}
        validator={validator}
        hasError={hasError}
        value={answer}
        handleOnYesNo={handleAnswer}
      />
    )
  } else if (type === TYPE_NUMBER) {
    input = (
      <NumberInput
        hasError={hasError}
        value={answer}
        handleOnChange={handleAnswer}
      />
    )
  }
  if (Array.isArray(options) && options.length > 0) {
    optionsBullets = (
      <ul className="options-bullets">
        {options.map((o, i) => (
          <li key={i}>{o}</li>
        ))}
      </ul>
    )
  }

  return (
    <div className={'question-row'}>
      <div>
        <strong>Q{i + 1}</strong>. {text}
        <br />
        {options && optionsBullets}
        {input}
      </div>
    </div>
  )
}

// After building this I realized this is the same thing as a radio button.
// But let's just keep regular buttons for now.

const YesNo = ({
  value,
  colorsOnInitial,
  validator,
  hasError,
  handleOnYesNo,
}) => {
  const expectedAnswer = validator.eq
  const overrideBackgroundYes =
    expectedAnswer === 'yes' ? 'expected-success' : 'expected-failure'
  const overrideBackgroundNo =
    expectedAnswer === 'no' ? 'expected-success' : 'expected-failure'

  const yesSelected = value === 'yes' ? 'selected' : ''
  const noSelected = value === 'no' ? 'selected' : ''

  const isInvalid = hasError ? 'is-invalid' : ''

  const overrideYesClasses = `btn symptom-check-btn btn-yesno mr-2 ${isInvalid} ${overrideBackgroundYes} ${yesSelected}`
  const overrideNoClasses = `btn symptom-check-btn btn-yesno ${isInvalid} ${overrideBackgroundNo} ${noSelected}`

  const yesClasses = `btn ${
    value === 'yes' ? 'btn-primary' : 'btn-secondary'
  } btn-yesno mr-2 ${isInvalid}`

  const noClasses = `btn ${
    value === 'no' ? 'btn-primary' : 'btn-secondary'
  } btn-yesno ${isInvalid}`

  return (
    <div className="mt-3">
      <button
        type="button"
        className={colorsOnInitial ? overrideYesClasses : yesClasses}
        onClick={() => handleOnYesNo('yes')}
      >
        Yes
      </button>
      <button
        type="button"
        className={colorsOnInitial ? overrideNoClasses : noClasses}
        onClick={() => handleOnYesNo('no')}
      >
        No
      </button>
      <div className="invalid-feedback">Please select an option.</div>
    </div>
  )
}

const NumberInput = ({ value, hasError, handleOnChange }) => (
  <>
    <input
      className={`form-control mt-3 ${hasError && 'is-invalid'}`}
      type="number"
      step=".01"
      value={value}
      onChange={e => handleOnChange(e.target.value)}
    />
    <div className="invalid-feedback">Please enter a value.</div>
  </>
)

export default Question
