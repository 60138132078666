import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import StaticQuestion from '../components/StaticQuestion'
import Loading from '../components/Loading'
import { getSurveyResults } from '../api/survey'
import greenTick from '../assets/green-tick.svg'
import warning from '../assets/warning.svg'
import './DailyResults.scss'

const DailyResults = () => {
  const params = useParams()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [responses, setResponses] = useState([])
  const [flagged, setFlagged] = useState(null)

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        setError(null)
        const surveyId = params.id
        const { survey_template, flagged } = await getSurveyResults(surveyId)
        setResponses(survey_template.survey_questions)
        setFlagged(flagged)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        Bugsnag.notify(error)
        setError('There was an error loading data. Please refresh the page.')
      }
    }
    fetchData()
  }, [params.id])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="container standard-page results-page">
      <div className="results-header mb-3 my-2">
        {flagged ? (
          <img alt="Warning" src={warning} />
        ) : (
          <img alt="Success" src={greenTick} />
        )}
        {flagged ? (
          <div>
            <h1>Your Daily Screen Requires Attention</h1>
            <h4>Please contact your office health administrator.</h4>
          </div>
        ) : (
          <h1>You're all set!</h1>
        )}
      </div>
      {flagged ? (
        <div className="button-group">
          <Link
            to="/daily-screen"
            className="btn  btn-lg btn-block go-to-daily-screen"
          >
            Redo Symptom Check
          </Link>
        </div>
      ) : (
        <div>
          <p>Use this QR code to initiate the temperature check</p>
          <div className="button-group">
            <Link to="/my-badge" className="btn btn-lg btn-block go-to-badge">
              Go to My Badge
            </Link>
            <Link
              to="/daily-screen"
              className="btn  btn-lg btn-block go-to-daily-screen"
            >
              Redo Symptom Check
            </Link>
          </div>
        </div>
      )}
      <div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {responses.map((r, i) => (
          <StaticQuestion key={i} {...r} i={i} />
        ))}
      </div>
    </div>
  )
}

export default DailyResults
