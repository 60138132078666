import React, { useEffect, useRef, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
import {
  getGuestSurvey,
  getGuestQRCode,
  createManualScreen,
} from '../api/guest'
import { toCelsius } from '../utils/temperature'
import { HOME_PAGE_LINK } from '../constants/link'
import StaticQuestion from '../components/StaticQuestion'
import Loading from '../components/Loading'
import Cookies from 'js-cookie'

import './GuestSymptomCheckResult.scss'
import { ResultsHeader } from './ResultsHeader'

const INTERVAL_TO_REDIRECT = 2 * 60 * 1000 // timer to go back to pervious page

const getRedirectTime = counter => {
  if (counter < 60) return `${counter} seconds`

  const time = moment
    .utc(moment.duration(counter, 'seconds').asMilliseconds())
    .format('mm:ss')
  return `${time} minutes`
}

const sleep = time =>
  new Promise(r => {
    setTimeout(() => {
      r()
    }, time)
  })

const GuestSymptomCheckResult = () => {
  const formRef = useRef()
  const messageRef = useRef()
  const componentToPrintRef = useRef()
  const { id } = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isManualInput, setIsManualInput] = useState(false)
  const [temperature, setTemperature] = useState('')
  const [responses, setResponses] = useState([])
  const [flagged, setFlagged] = useState(null)
  const [qrCode, setQrCode] = useState()
  const [user, setUser] = useState({})
  const [counter, setCounter] = useState(INTERVAL_TO_REDIRECT / 1000)

  const { token } = getParams(search)

  useEffect(() => {
    async function fetchData() {
      if (!token) {
        window.location.href = HOME_PAGE_LINK
      }
      setIsLoading(true)
      setError(null)

      try {
        const data = await getGuestSurvey({
          id,
          token,
        })
        setUser(data.user)
        const { qr_code } = await getGuestQRCode({ id: data.user.id, token })
        const { survey_template } = data

        const flagged = survey_template.survey_questions.some(
          q => q.survey_result && q.survey_result.flagged
        )

        setResponses(survey_template.survey_questions)
        setFlagged(flagged)
        setQrCode(encodeURIComponent(qr_code))
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        Bugsnag.notify(error)
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [id, setUser, token])

  useEffect(() => {
    const enabled = Boolean(parseInt(Cookies.get('auto_refresh_enabled')))
    if (!enabled) {
      return
    }

    let timer
    if (counter > 0) {
      timer = setTimeout(() => {
        setCounter(c => c - 1)
      }, 1000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [counter])

  useEffect(() => {
    if (counter === 0) {
      history.goBack()
    }
  }, [counter, history])

  const goBack = () => history.goBack()

  // Temporarily hide it
  // eslint-disable-next-line no-unused-vars
  const toggleManualInput = () => setIsManualInput(!isManualInput)

  const setTemperatureValue = e => {
    setTemperature(e.target.value)
  }

  const validateTemperature = () => {
    if (!formRef.current) return
    const temperatureInput = formRef.current.querySelector('#temperature-input')
    if (!temperature) {
      temperatureInput.classList.add('is-invalid')
      return false
    }
    temperatureInput.classList.remove('is-invalid')
    return true
  }

  const handleSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()
    const isFormValid = validateTemperature()
    if (!isFormValid) return
    // TODO: relate the temperature unit with config
    const tempCelsius = toCelsius(Number(temperature))
    await createManualScreen(token, tempCelsius)
    // TODO: this is just a workaround, should be replaced with message component or library
    messageRef.current.style.transform = 'translateY(0)'
    await sleep(2000)
    goBack()
  }

  const formatBadgeUserName = (first_name, last_name) => {
    const nameMaxLength = 6
    const userFirstName = first_name
      ? first_name.length > nameMaxLength
        ? first_name.slice(0, nameMaxLength).concat('.')
        : first_name
      : ''
    const userLastName = last_name
      ? last_name.length > nameMaxLength
        ? last_name.slice(0, nameMaxLength).concat('.')
        : last_name
      : ''
    return `${userFirstName} ${userLastName}`.trim()
  }

  const printBadge = useReactToPrint({
    content: () => componentToPrintRef.current,
  })

  const ComponentToPrint = React.forwardRef((props, ref) => {
    const contentTextSize = 36
    const contentMargin = 10
    const contentHeight = contentTextSize + contentMargin
    const totalHeight = contentHeight * 3
    const viewBoxHwRatio = 2
    const totalWidth = totalHeight * viewBoxHwRatio
    const contentViewBox = `0 0 ${totalWidth} ${totalHeight}`
    const nameTextSize = 36
    const nameTextMargin = 24
    const nameHeight = nameTextSize + nameTextMargin
    const nameViewBoxHwRatio = 7
    const maxNameLength = 12
    const firstNameLength = Math.min(
      6,
      user.first_name ? user.first_name.length : 0
    )
    const lastNameLength = Math.min(
      6,
      user.last_name ? user.last_name.length : 0
    )
    const fullNameWidth = nameHeight * nameViewBoxHwRatio
    const nameWidth =
      (fullNameWidth * (firstNameLength + lastNameLength)) / maxNameLength
    const nameViewBox = `0 0 ${fullNameWidth} ${nameHeight}`
    return (
      <>
        <div ref={ref} className="print">
          <div className="rotate-wrap">
            <div className="qr-wrap">
              <div className="qr_code-image">
                {qrCode && (
                  <img
                    alt="QR Code"
                    src={`data:image/svg+xml;utf8,${qrCode}`}
                  />
                )}
              </div>
              <div className="qr_code-note">
                <svg style={{ width: '100%' }} viewBox={contentViewBox}>
                  <text x="0" y={contentTextSize}>
                    <tspan x="0" dy="0" fontSize={contentTextSize}>
                      Health check-in
                    </tspan>
                    <tspan x="0" dy={contentHeight} fontSize={contentTextSize}>
                      successful
                    </tspan>
                    <tspan
                      x="0"
                      dy={contentHeight}
                      fontSize={contentTextSize * 0.8}
                    >
                      {new Date().toDateString()}
                    </tspan>
                  </text>
                </svg>
              </div>
            </div>
            <div className="guest-name">
              <svg
                style={{
                  width: '90%',
                  marginLeft: '80px',
                }}
                viewBox={nameViewBox}
              >
                <text x="0" y={nameTextSize} textAnchor="middle">
                  <tspan
                    x={nameWidth / 2}
                    y="0"
                    fontSize={nameTextSize}
                    dy={nameTextSize}
                    fontWeight="bold"
                    textLength={nameWidth}
                  >
                    {formatBadgeUserName(user.first_name, user.last_name)}
                  </tspan>
                </text>
              </svg>
            </div>
          </div>
        </div>
      </>
    )
  })

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return (
      <div className="container standard-page results-page guest-survey-result">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    )
  }

  return (
    <div className="container standard-page results-page guest-survey-result">
      <div
        ref={messageRef}
        className="submit-message alert alert-success"
        role="alert"
      >
        Temperature submitted successfully!
      </div>
      <ResultsHeader
        flagged={flagged}
        isManualInput={isManualInput}
        countdown={getRedirectTime(counter)}
        handlePrintBadge={printBadge}
      />
      {!flagged && (
        <>
          {/* <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="switch"
              checked={isManualInput}
              onChange={toggleManualInput}
            />
            <label className="custom-control-label" htmlFor="switch">
              Input the temperature manually in Fahrenheit
            </label>
          </div> */}
          {isManualInput ? (
            <div className="manual-input">
              <form id="form" ref={formRef} onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="temperature-input">Temperature (°F)</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    id="temperature-input"
                    value={temperature}
                    onChange={setTemperatureValue}
                    onBlur={validateTemperature}
                  />
                  <div className="invalid-feedback">Invalid temperature</div>
                </div>
              </form>
            </div>
          ) : (
            <>
              <div className="qr_code-header">
                <h4>
                  Please use the QR code below to activate the temperature kiosk
                  inside.
                </h4>
              </div>
              <div className="qr_code-image__container">
                <div className="qr_code-image">
                  {qrCode && (
                    <img
                      alt="QR Code"
                      src={`data:image/svg+xml;utf8,${qrCode}`}
                    />
                  )}
                </div>
              </div>
              <div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {!isManualInput &&
        responses.map((r, i) => <StaticQuestion key={i} {...r} i={i} />)}
      {isManualInput && (
        <button
          className="btn btn-primary btn-screen-submit btn-block"
          type="submit"
          form="form"
          disabled={!temperature}
        >
          Submit
        </button>
      )}
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentToPrintRef} />
      </div>
    </div>
  )
}

const getParams = search => {
  return Object.fromEntries(new URLSearchParams(search))
}

export default GuestSymptomCheckResult
